import 'moment/locale/ja';
// TODO: どこでfirebaseのinitializeAppを行うかは検討
import '@/firebase/firebase';
import '@e-seikatsu/design-system/dist/tokens/index.css';

import CompositionAPI from '@vue/composition-api';
import axios from 'axios';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import Router from 'vue-router';
import VueScrollTo from 'vue-scrollto';
// @ts-ignore
import VueTheMask from 'vue-the-mask';
import VuetifyToast from 'vuetify-toast-snackbar-ng';
import Vuex from 'vuex';

import App from '@/inputform/App.vue';
import { createRouter } from '@/inputform/router';
import { createStore } from '@/inputform/store';
import VueLoading from '@/plugins/loading';
import vuetify from '@/plugins/vuetify';
import { errorHandler, warnHandler } from '@/utilities/appLogger';
import Filters from '@/utilities/Filters';

if (import.meta.env.NODE_ENV !== 'production') {
  console.log(`git hash: ${import.meta.env.VITE_GIT_HASH}`);
}

Vue.config.productionTip = false;

Vue.filter('moneyDelimiter', Filters.MoneyDelimiter);
Vue.filter('monthAlignment', Filters.MonthAlignment);
Vue.filter('dateTimeDisplay', Filters.DateTimeDisplay);
Vue.filter('dateInputField', Filters.DateInputField);

Vue.use(CompositionAPI);
Vue.use(Vuex);
Vue.use(Router);
Vue.use(VueTheMask);
Vue.use(VueLoading);
Vue.use(VueScrollTo);
Vue.use(VuetifyToast, {
  x: '',
  y: 'top',
  timeout: 6000,
  showClose: true,
  closeColor: 'white',
  shorts: {
    success: {
      color: 'success',
      icon: 'mdi-check-circle'
    },
    info: {
      color: 'info',
      icon: 'info'
    },
    warning: {
      color: 'warning',
      icon: 'mdi-alert-circle'
    },
    error: {
      color: 'error',
      icon: 'mdi-alert'
    }
  },
  property: '$toast'
});

const store = createStore();
const router = createRouter(store);

Vue.use(
  VueGtag,
  {
    config: {
      id: import.meta.env.VITE_ANALYTICS_ID
    }
  },
  router
);

Vue.config.errorHandler = errorHandler;
Vue.config.warnHandler = warnHandler;

type MaintenanceFlagJson = {
  prd: {
    isMaintenance: boolean;
  };
  inside: {
    isMaintenance: boolean;
  };
};

const maintenanceFlagJson: string | undefined = import.meta.env
  .VITE_MAINTENANCE_FLAG_URL;
const maintenanceInfoIntervalTime: number = 10 * 60 * 1000;

const getMaintenanceInfo = async () => {
  if (!maintenanceFlagJson) {
    return;
  }
  const maintenanceData = await axios
    .get<MaintenanceFlagJson>(maintenanceFlagJson)
    .then(resp => resp.data)
    .catch(() => undefined);

  if (!maintenanceData) {
    return;
  }

  return maintenanceData.prd.isMaintenance;
};

const checkMaintenanceFlag = async () => {
  const maintenanceFlag = await getMaintenanceInfo();

  if (maintenanceFlag && !location.pathname.includes('maintenance')) {
    if (location.pathname.includes('signIn')) {
      const requestUID = location.pathname.match(/\/([\w-]+)$/)?.[1];
      window.location.href = location.origin + '/maintenance/' + requestUID;
    } else {
      const requestUID = location.pathname.match(
        /\/([\w-]+)(?:\/[\w-]+)?$/
      )?.[1];
      window.location.href = location.origin + '/maintenance/' + requestUID;
    }
  }

  //メンテナンス中以外にエラーページにいた時、その前のページにリダイレクト
  if (!maintenanceFlag && location.pathname.includes('maintenance')) {
    router.go(-1);
  }
};
checkMaintenanceFlag();
window.setInterval(checkMaintenanceFlag, maintenanceInfoIntervalTime);

new Vue({
  components: { App },
  render: h => h(App),
  router,
  store,
  template: '<App/>',
  vuetify
}).$mount('#app');
