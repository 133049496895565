<template>
  <v-app>
    <v-main>
      <v-container class="pa-0" style="max-width: 1024px; height: 100%">
        <v-overlay
          style="z-index: 999"
          :absolute="$loading.absolute"
          :value="$loading.state"
        >
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-fade-transition mode="out-in">
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {}
</script>

<style>
.required::after {
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  content: "必須";
  color: #fff;
  background-color: #ff5252;
  padding: 2px 8px;
  margin-left: 12px;
  border-radius: 2px;
  word-break: keep-all;
}
.required {
  display: flex;
  align-items: center;
}
.required_cb::before {
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  content: "必須";
  color: #fff;
  background-color: #ff5252;
  display: block;
  width: 40px;
  padding: 2px 8px;
  margin-bottom: 4px;
  border-radius: 2px;
}
.required_cb {
  align-items: center;
}
.subttl-mb {
  background-color: rgba(17, 51, 100, 0.65);
  color: #fff;
  font-weight: bold;
  border-left: 8px solid #07c0d3;
  border-radius: 0 !important;
}
.subttl-mb .mdi:before {
  color: #fff;
}
.pnl-mb {
  border-radius: 0 !important;
}
.v-subheader {
  padding-left: 0;
}
</style>
