
<v-app>
  <v-main>
    <v-container class="pa-0" style="max-width: 1024px; height: 100%">
      <v-overlay
        style="z-index: 999"
        :absolute="$loading.absolute"
        :value="$loading.state"
      >
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-container>
  </v-main>
</v-app>
