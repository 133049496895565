import { createStore as cs, Module } from 'vuex-smart-module';

import { AppLocalModule } from '@/inputform/store/AppLocalModule';
import { SignInModule } from '@/inputform/store/SignInModule';
import { RequestDocumentModule } from '@/store/RequestDocumentModule';

export function createStore() {
  const rootModule = new Module({
    modules: {
      RequestDocumentModule,
      SignInModule,
      AppLocalModule
    }
  });

  return cs(rootModule);
}
