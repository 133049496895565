import { Actions, Getters, Module, Mutations } from 'vuex-smart-module';

import { VFormObj } from '@/plugins/vuetify';

class AppLocalState {
  initCompleted: boolean = false;
  isValid: boolean = false;
  requestForm: VFormObj | null = null;
}

class AppLocalGetters extends Getters<AppLocalState> {
  get getInitCompleted() {
    return this.state.initCompleted;
  }
  get getIsValid() {
    return this.state.isValid;
  }
}

class AppLocalMutations extends Mutations<AppLocalState> {
  setInitComplete(val: boolean) {
    this.state.initCompleted = val;
  }
  setIsValid(isValid: boolean) {
    this.state.isValid = isValid;
  }
  setRequestForm(ref: VFormObj) {
    this.state.requestForm = ref;
  }
}

class AppLocalActions extends Actions<
  AppLocalState,
  AppLocalGetters,
  AppLocalMutations,
  AppLocalActions
> {
  initComplete() {
    this.commit('setInitComplete', true);
  }
  setIncomplete() {
    this.commit('setInitComplete', false);
  }
  setIsValid(isValid: boolean) {
    this.commit('setIsValid', isValid);
  }
  setRequestForm(ref: VFormObj) {
    this.commit('setRequestForm', ref);
  }
  requestFormValidate() {
    const requestForm = this.state.requestForm;
    if (!requestForm) return false;
    return this.state.requestForm?.validate();
  }
}

export const AppLocalModule = new Module({
  state: AppLocalState,
  getters: AppLocalGetters,
  mutations: AppLocalMutations,
  actions: AppLocalActions
});
