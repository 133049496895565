import { getRedirectResult } from 'firebase/auth';
import { isDefined, isString } from 'requestform-types/lib/TypeGuard';
import Router from 'vue-router';
import { RouteConfig } from 'vue-router';
import { Store } from 'vuex';

import { auth } from '@/firebase/firebase';
import { AppLocalModule } from '@/inputform/store/AppLocalModule';
import { SignInModule } from '@/inputform/store/SignInModule';
import {
  getIsAllowProxyInput,
  getIsArchiveRequest
} from '@/store/RequestDocumentModule';
import { appLogger, parseError } from '@/utilities/appLogger';

const Request = () =>
  import(/* webpackChunkName: "request"*/ './views/Request.vue');
const SignIn = () =>
  import(/* webpackChunkName: "signIn"*/ './views/SignIn.vue');
const CheckContract = () =>
  import(
    /* webpackChunkName: "checkContract"*/ './views/request/CheckContract.vue'
  );
const Moshikomisha = () =>
  import(
    /* webpackChunkName: "moshikomisha"*/ './views/request/Moshikomisha.vue'
  );
const Nyukyosha = () =>
  import(/* webpackChunkName: "nyukyosha"*/ './views/request/Nyukyosha.vue');
const Renrakusaki = () =>
  import(
    /* webpackChunkName: "Renrakusaki"*/ './views/request/Renrakusaki.vue'
  );
const ShodakuJiko = () =>
  import(
    /* webpackChunkName: "shodakuJiko"*/ './views/request/ShodakuJiko.vue'
  );
const Top = () =>
  import(/* webpackChunkName: "top"*/ './views/request/Top.vue');
const SignOut = () =>
  import(/* webpackChunkName: "signOut"*/ '@/inputform/views/SignOut.vue');
const MaintenancePage = () =>
  import(/* webpackChunkName: "errorPage"*/ './views/MaintenancePage.vue');

export const RequestChildRoutes: RouteConfig[] = [
  {
    path: 'contract',
    name: 'CheckContract',
    props: true,
    component: CheckContract
  },
  {
    path: 'agreement',
    name: 'ShodakuJiko',
    props: true,
    component: ShodakuJiko
  },
  {
    path: 'applicant',
    name: 'Moshikomisha',
    props: true,
    component: Moshikomisha
  },
  {
    path: 'cohabitant',
    name: 'Nyukyosha',
    props: true,
    component: Nyukyosha
  },
  {
    path: 'guarantor',
    name: 'Renrakusaki',
    props: true,
    component: Renrakusaki
  },
  {
    path: 'top',
    name: 'Top',
    alias: '/',
    props: true,
    component: Top
  }
];

export const AppRoutes: RouteConfig[] = [
  {
    path: '/:requestUID',
    name: 'Request',
    component: Request,
    props: true,
    children: RequestChildRoutes
  }
];

export const SignInRoute: RouteConfig = {
  path: '/signIn/:requestUID',
  name: 'signIn',
  component: SignIn,
  props: true,
  meta: {
    noAuth: true
  }
};

export const SignOutRoute: RouteConfig = {
  path: '/signOut/:requestUID',
  name: 'signOut',
  component: SignOut,
  props: true,
  meta: {
    noAuth: true
  }
};

export const MaintenancePageRoute: RouteConfig = {
  path: '/maintenance/:requestUID',
  name: 'MaintenancePage',
  component: MaintenancePage
};

export const Routes: RouteConfig[] = [
  ...AppRoutes,
  SignInRoute,
  SignOutRoute,
  MaintenancePageRoute
];

let redirected = false;
export function createRouter(store: Store<any>) {
  const router = new Router({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes: Routes
  });

  const signInCtx = SignInModule.context(store);
  const appLocalCtx = AppLocalModule.context(store);

  // TODO: fix types
  router.beforeEach(async (to, from, next) => {
    // beforeEach内で動的にroutingしたときはそのまま解決する
    if (redirected) {
      redirected = false;
      next();
      return;
    }
    const requestUID = to.params.requestUID || '';
    const signOut = () => {
      appLocalCtx.actions.setIncomplete();
      next({
        path: `signOut/${requestUID}`
      });
    };
    const signedInFunc = async () => {
      if (!appLocalCtx.getters.getInitCompleted) {
        await signInCtx.actions.updateCurrentUser();
        appLocalCtx.actions.initComplete();
      }
      const user = auth.currentUser;
      if (user) {
        appLogger.debug(`inputform 画面遷移: ${from.path} -> ${to.path}`, {
          requestUID,
          from: from.path,
          to: to.path
        });
        const isArchive = await getIsArchiveRequest(requestUID).catch(e => {
          console.error(e);
          return;
        });

        // NOTE: 代理入力を許可していない申込かつ、申込者ではない場合はログアウトして、ルートに遷移
        const domainUID = signInCtx.getters.domainUID;
        if (domainUID) {
          const isAllowProxyInput = await getIsAllowProxyInput(
            requestUID
          ).catch(e => {
            appLogger.error(e, { domainUID, requestUID, accountUID: user.uid });
            return;
          });
          if (!isAllowProxyInput) {
            redirected = true;
            signOut();
            return;
          }
        }

        // NOTE: 申込へのアクセスに失敗した場合はログアウトして、ルートに遷移
        if (!isDefined(isArchive)) {
          redirected = true;
          signOut();
          return;
        }
        if (isArchive) {
          // NOTE: アーカイブ済みならアラート表示用のキャッシュをセット&ログアウトして、ルートに遷移
          localStorage.setItem('isRequestArchive', 'true');
          redirected = true;
          signOut();
          return;
        }
      }
      next();
    };

    // NOTE: custom_token パラメータが付いていたらカスタムトークンで自動ログインする
    if (to.name === SignInRoute.name) {
      const customToken = to.query['custom_token'];
      if (customToken && isString(customToken)) {
        const isSignIn = await signInCtx.actions
          .signInWithCustomToken(customToken)
          .then(() => true)
          .catch(e => {
            appLogger.error('failed signin with custom token', parseError(e));
            return false;
          });
        if (isSignIn) {
          signedInFunc();
          next({
            path: `/${requestUID}`
          });
          return;
        }
      }
    }

    const noAuthRoute = to.matched.every(record => record.meta.noAuth);
    if (noAuthRoute) {
      next();
      return;
    } else if (!auth.currentUser) {
      await getRedirectResult(auth);
      if (auth.currentUser) {
        signedInFunc();
      } else {
        redirected = true;

        next({
          path: `/signIn/${requestUID}`
        });
      }
      return;
    } else {
      signedInFunc();
      return;
    }
  });
  return router;
}
